<template>
  <div>
    <!-- <AppHeader /> -->
    <v-divider></v-divider>
    <v-layout wrap justify-center fill-height>
      <v-flex xs12 sm12 md12 lg2 hidden-md-and-down>
        <v-navigation-drawer
          class="fixedSidebar"
          v-model="sideNav"
          clipped
          overlay-color="yellow"
          width="100%"
          height="100vh"
          permanent
          color="grey"
        >
          <v-list>
            <v-list-item-group v-model="model">
              <v-list-item class="pb-3" v-for="(item, i) in menus2" :key="i">
                <v-list-item-icon>
                  <router-link :to="item.route" style="text-decoration: none">
                    <v-icon color="#343666" x-samll v-text="item.icon"></v-icon>
                  </router-link>
                </v-list-item-icon>

                <v-list-item-content>
                  <router-link :to="item.route" style="text-decoration: none">
                    <v-list-item-title
                      style="
                        font-size: 14px;
                        letter-spacing: 2px;
                        color: white;
                        cursor: pointer;
                        font-family: pbold;
                      "
                      v-text="item.name"
                    ></v-list-item-title>
                  </router-link>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-flex>
      <v-flex xs12 sm12 md12 lg10 pt-12 pt-lg-0>
        <v-layout
          wrap
          justify-center
          :style="
            $route.name == 'Dashboard'
              ? 'background-color: #FFFFFF'
              : 'background-color: #fff9c4'
          "
        >
          <v-flex xs12 pa-0>
            <v-card
              min-height="100vh"
              :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
              <v-layout wrap justify-center>
                <v-flex xs12 pa-4>
                  <router-view :key="$route.fullPath"> </router-view>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import AppHeader from "./../layout/AppHeader";
export default {
  components: {
    // AppHeader,
  },
  data() {
    return {
      sideNav: true,
      admin: [{ name: "ADMINS", route: "/Admins" }],

      menus2: [
        {
          name: "Dashboard",
          route: "/admin",
          icon: "mdi-view-dashboard",
        },

        {
          name: "User List",
          route: "/userlist",
          icon: "mdi-account",
        },
        {
          name: "Privacy Policy",
          route: "/privacy",
          icon: "mdi-nix",
        },
         {
          name: "Reports",
          route: "/reports",
          icon: "mdi-file",
        },
       
      ],
      menus: [
        {
          name: "Dashboard",
          subMenu: [
            {
              name: "board",
              route: "/admin",
            },
          ],
        },

        {
          name: "User List",
          subMenu: [
            {
              name: "User List",
              route: "/userlist",
            },
          ],
        },
        {
          name: "Privacy Policy",
          subMenu: [
            {
              name: "Privacy Policy",
              route: "/privacy",
            },
          ],
        },
      ],
    };
  },
  computed: {
    appType() {
      return this.$store.state.userType;
    },
    appMenu() {
      // console.log("menus", store.state.menus);
      return this.menus;
    },
    navItems() {
      if (this.appType == "subadmin") return this.userNavItemsSubAdmin;
      else if (this.appType == "programadmin")
        return this.userNavItemsProgramAdmin;
      else if (this.appType == "staticcontentadmin")
        return this.userNavItemsStaticAdmin;
      else if (this.appType == "accountsadmin")
        return this.userNavItemsAccountsAdmin;
      else return this.userNavItems;
    },
  },
};
</script>
<style>
@media only screen and (min-device-width: 767px) {
  .fixedSidebar {
    /* position: fixed; */

    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 50;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .fixedSidebar {
    display: none !important;
  }
}
/* .activated { */
.activated {
  color: white !important;
}
/* } */
</style>
